import React, { useEffect, useContext, useState, useRef } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Check } from 'react-feather';

import ApplicationContext from '../../ApplicationContext.js';
import { ErrorModalContext } from '../../Modals/ErrorModal.jsx';
import ErrorModal from '../../Modals/ErrorModal.jsx';
import { useOktaAuthContext } from '../../OktaAuthContext.js';
import Spinner from '../../shared/Loader/Spinner';

import {
  addItemToShoppingCart,
  removeItemFromShoppingCart,
} from '../../backend/shoppingcart.js';

const Container = styled.div`
  display: flex;
  justify-content: ${(props) => (props.$isToolCompare ? 'center' : 'flex-start')};
  width: 100%;
`;

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: end;

  @media (max-width: 1500px) and (min-width: 1024px) {
    ${(props) =>
      props.$isToolCompare && !props.$isMobile ?
      `
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 10px;
    ` : ''}
  }

  @media (max-width: 1024px) {
    justify-content: ${(props) => (props.$isToolCompare ? 'center' : 'space-between')};
    width: 100%;
  }
`;

const StyledCustomInput = styled.input`
  width: 52px;
  height: 30px;
  border: solid 1px var(--color-gray3);
  box-sizing: border-box;
  font-family: Univers;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: var(--color-gray1);
  padding: 0;
  margin-left: 4px;
`;

const NumericInputLabel = styled.label`
  font-family: Univers;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: var(--color-gray1);
`;

const BaseButton = styled.button`
  font-family: Univers;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  padding: 0;
  margin-left: 12px;
  cursor: pointer;
  border: none;

  &:disabled {
    color: var(--color-gray3);
    background-color: #fff;
    cursor: default;
  }
`;

const DesktopAddUpdateButton = styled(BaseButton)`
  width: ${(props) => props.$width || '182px'};
  height: ${(props) => props.$height || '40px'};
  background-color: var(--color-primary);
  color: #fff;
  font-style: ${(props) => (props.$grayedOut ? 'italic' : 'normal')};
`;

const MobileDefaultAddUpdateButton = styled(BaseButton)`
  width: ${(props) => props.$width || 'fit-content'};
  height: ${(props) => props.$height || '40px'};
  background-color: var(--color-primary);
  color: #fff;
  font-style: ${(props) => (props.$grayedOut ? 'italic' : 'normal')};
`;

const MobileSuggestedAddUpdateButton = styled(BaseButton)`
  width: 70px;
  height: ${(props) => props.$height || '40px'};
  background-color: #fff;
  color: var(--color-primary);
  font-style: ${(props) => (props.$grayedOut ? 'italic' : 'normal')};
  margin: 0; /* override margin if needed */
`;

const RemoveButton = styled(BaseButton)`
  width: ${(props) => props.$width || '182px'};
  height: ${(props) => props.$height || '40px'};
  background-color: #fff;
  color: var(--color-primary);
  font-style: ${(props) => (props.$grayedOut ? 'italic' : 'normal')};
`;

const InCartButton = styled(BaseButton)`
  width: ${(props) => props.$width || '182px'};
  height: ${(props) => props.$height || '40px'};
  background-color: #fff;
  color: #008185;
  font-style: ${(props) => (props.$grayedOut ? 'italic' : 'normal')};
  cursor: auto;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 99;
`;

const UpdateItemInCartForm = ({
  toolData,
  oktaRedirectOnLoginTo,
  buttonWidth,
  buttonHeight,
  isToolCompare,
  isShoppingCartView,
  isSuggestedItems,
  setIsRemovingItem,
}) => {
  const {
    shoppingCartItemsData,
    updateCartItemsNumber,
    updateShoppingCartData,
    classType,
  } = useContext(ApplicationContext);
  const [, setError] = useContext(ErrorModalContext);
  const { openModal } = useOktaAuthContext();

  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const initialCartQuantityRef = useRef(null);
  const [inputValue, setInputValue] = useState('1');
  const [quantityInCart, setQuantityInCart] = useState(0);
  const [userInputDifferenceWithCart, setUserInputDifferenceWithCart] =
    useState(0);
  const [isRemoving, setIsRemoving] = useState(false);

  const isInCart = quantityInCart > 0;

  const isAddOrUpdate =
    !isInCart || (isInCart && userInputDifferenceWithCart !== 0);
  const showRemoveLabel =
    isShoppingCartView && isInCart && userInputDifferenceWithCart === 0;

  const handleMutationError = (
    error,
    oktaRedirectOnLoginTo,
    errorCodePrefix
  ) => {
    const errorCode = error.message.split('/');
    if (error.constructor.name === 'TypeError') {
      setError({
        title: 'Looks like something went wrong',
        message:
          'Network connection lost. Please check your network connection and try again.',
      });
    } else if (errorCode[1] === '401') {
      openModal(oktaRedirectOnLoginTo);
    } else {
      setError({
        title: 'Looks like something went wrong',
        message: `We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below`,
        errorCode: `#${errorCodePrefix}-${errorCode[1]}`,
      });
    }
  };

  const { mutate: mutateShoppingCartList, isLoading: isAddingToShoppingCart } =
    useMutation(
      (data) =>
        addItemToShoppingCart(
          data.productData,
          data.sap.toString(),
          data.quantity
        ),
      {
        onError: (error) =>
          handleMutationError(error, oktaRedirectOnLoginTo, 'SC-2'),
        onSuccess: (response) => {
          updateShoppingCartData(response);
          updateCartItemsNumber(response.length);
        },
      }
    );

  const {
    mutate: removeFromShoppingCartList,
    isLoading: isRemovingFromShoppingCart,
  } = useMutation(
    (data) => removeItemFromShoppingCart(data.sap.toString(), data.quantity),
    {
      onError: (error) =>
        handleMutationError(error, oktaRedirectOnLoginTo, 'SC-3'),
      onSuccess: (response) => {
        updateShoppingCartData(response);
        updateCartItemsNumber(response.length);
        setIsRemovingItem(false);
      },
    }
  );

  // Update quantity when external shopping cart data changes.
  useEffect(() => {
    const toolInCart = shoppingCartItemsData?.find(
      (tool) => tool.SapNumber?.toString() === toolData.SapNumber?.toString()
    );
    const newQuantity = toolInCart ? toolInCart.Quantity : 0;
    setQuantityInCart(newQuantity);

    if (
      initialCartQuantityRef.current === null ||
      initialCartQuantityRef.current !== newQuantity
    ) {
      setInputValue(newQuantity ? newQuantity.toString() : '1');
      initialCartQuantityRef.current = newQuantity;
    }
  }, [shoppingCartItemsData, toolData]);

  // Recalculate the difference whenever external quantity or input value changes.
  useEffect(() => {
    const diff = parseInt(inputValue, 10) - quantityInCart;
    setUserInputDifferenceWithCart(isNaN(diff) ? 0 : diff);
  }, [quantityInCart, inputValue]);

  const handleUserInput = (event) => {
    const newValue = event.target.value;
    if (
      newValue === '' ||
      (!isInCart
        ? /^[1-9]\d*$/.test(newValue)
        : /^(0|[1-9]\d*)$/.test(newValue))
    ) {
      setInputValue(newValue);
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (!isInCart) {
      // Add item if not in cart.
      mutateShoppingCartList({
        sap: toolData?.SapNumber,
        productData: toolData?.Name,
        quantity: inputValue,
      });
    } else if (userInputDifferenceWithCart !== 0) {
      // Update quantity if the user has changed the input.
      mutateShoppingCartList({
        sap: toolData?.SapNumber,
        productData: toolData?.Name,
        quantity: inputValue,
      });
    }
  };

  const handleRemoveItem = () => {
    setIsRemoving(true);
    removeFromShoppingCartList({
      sap: toolData?.SapNumber,
      quantity: quantityInCart,
    });
  };

  useEffect(() => {
    isRemovingFromShoppingCart && setIsRemovingItem(true);
  }, [isRemovingFromShoppingCart]);

  const isLoading = isAddingToShoppingCart || isRemovingFromShoppingCart;

  const renderButtonContent = () => {
    if (isLoading) {
      return (
        <SpinnerContainer>
          <Spinner active extraSmall={true} />
        </SpinnerContainer>
      );
    }
    if (!isInCart) {
      return 'ADD TO CART';
    }
    if (isInCart && userInputDifferenceWithCart !== 0) {
      if (isMobile && isSuggestedItems) {
        return (
          <Check color={'var(--color-primary)'} size={16} strokeWidth={4} />
        );
      }
      return 'UPDATE QTY';
    }

    if (showRemoveLabel) {
      return classType === 'compact' ? 'X' : 'REMOVE';
    }
    return 'IN CART';
  };
  // Choose which button component to render.
  let ButtonComponent = null;
  let buttonProps = {
    $width: buttonWidth,
    $height: buttonHeight,
    $grayedOut: isLoading,
    disabled: isLoading || isRemoving,
  };

  const isIconOnly =
    (isMobile &&
      isSuggestedItems &&
      isAddOrUpdate &&
      userInputDifferenceWithCart !== 0) ||
    (classType === 'compact' && showRemoveLabel);

  if (showRemoveLabel) {
    ButtonComponent = RemoveButton;
  } else if (isAddOrUpdate) {
    if (isMobile) {
      ButtonComponent = isSuggestedItems
        ? MobileSuggestedAddUpdateButton
        : MobileDefaultAddUpdateButton;
    } else {
      ButtonComponent = DesktopAddUpdateButton;
    }
  } else {
    ButtonComponent = InCartButton;
  }

  if (isMobile) {
    if (isIconOnly) {
      buttonProps.$width = '20px';
    } else if (!isAddOrUpdate && isSuggestedItems) {
      buttonProps.$width = '70px';
    }
  } else {
    if (showRemoveLabel) {
      buttonProps.$width = '182px';
    } else if (!isAddOrUpdate) {
      buttonProps.$width = isSuggestedItems ? '182px' : '130px';
    }
  }

  if (isMobile && isSuggestedItems) {
    buttonProps.style = { margin: '0', marginRight: '8px' };
  }

  const onClickHandler =
    isShoppingCartView && isInCart && userInputDifferenceWithCart === 0
      ? handleRemoveItem
      : undefined;

  return (
    <Container $isToolCompare={isToolCompare}>
      <ErrorModal />
      <StyledForm $isToolCompare={isToolCompare} onSubmit={onFormSubmit}>
        {
        isToolCompare 
        ? (isAddOrUpdate) && <NumericInputLabel>
          Qty
          <StyledCustomInput
            disabled={isLoading}
            type="number"
            min={0}
            value={inputValue}
            step={1}
            onChange={handleUserInput}
          />
        </NumericInputLabel>

        : <NumericInputLabel>
          Qty
          <StyledCustomInput
            disabled={isLoading}
            type="number"
            min={0}
            value={inputValue}
            step={1}
            onChange={handleUserInput}
          />
        </NumericInputLabel>}

        <ButtonComponent {...buttonProps} onClick={onClickHandler}>
          {renderButtonContent()}
        </ButtonComponent>
      </StyledForm>
    </Container>
  );
};

export default UpdateItemInCartForm;
