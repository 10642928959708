import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { ErrorModalContext } from '../../Modals/ErrorModal';
import ErrorModal from '../../Modals/ErrorModal.jsx';
import { useNavigate, useLocation } from 'react-router-dom';
import { useOktaAuthContext } from '../../OktaAuthContext.js';
import { useOktaAuth } from '@okta/okta-react';

import ShoppingCartViewContext from './ShoppingCartViewContext.js';
import ApplicationContext from '../../ApplicationContext.js';
import { ReturningUserContextProvider } from './ReturningUserContext.js';

import MyCart from './MyCart.jsx';
import SuggestedItems from './SuggestedItems.jsx';
import ClientFormPage from './ClientFormPage.jsx';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SuccessModal, { useSuccessModal } from '../../Modals/SuccessModal.jsx';
import DataFetchErrorPlaceholder from '../../shared/DataFetchErrorPlaceholder.jsx';
import { appInsights } from '../../appInsights.js';

import {
  addItemToShoppingCart,
  removeItemFromShoppingCart,
  removeAllItemsFromShoppingCart,
  requestQuote,
} from '../../backend/shoppingcart.js';

const ShoppingCartViewContainer = styled.div`
  background-color: var(--color-gray6);
  width: 100%;
  min-height: calc(100vh - 150px);
  padding-top: 12px;
  padding-bottom: 20px;

  @media (max-width: 1024px) {
    padding: 0;
    background-color: #fff;
  }
`;

const LoaderOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-right: 112px;
  margin-left: 112px;
  @media (max-width: 1024px) {
    margin-right: 5%;
    margin-left: 5%;
  }
`;
const SkeletonLoaderContainer = styled.div`
  margin-left: 8px;
  margin-right: 12px;
`;

const SkeletonRow = styled(Skeleton)`
  margin-bottom: 10px;
`;

const MyCartTitle = styled.div`
  font-family: Univers;
  color: var(--color-gray1);
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 18px;
  margin-top: 24px;
  margin-left: 18px;

  @media (max-width: 1024px) {
    font-size: 22px;
    margin-bottom: 12px;
    margin-top: 14px;
    margin-left: 0;
  }
`;

const DataFetchErrorPlaceholderContainer = styled.div`
  height: 72vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignInMessage = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--color-gray3);
  font-size: 28px;
  font-weight: 500;
`;

const ShoppingCart = () => {
  const {
    updateCartItemsNumber,
    isShoppingCartLoading,
    shoppingCartItemsData,
    updateShoppingCartData,
    shoppingCartError,
  } = useContext(ApplicationContext);

  const [, setError] = useContext(ErrorModalContext);
  const { openModal } = useOktaAuthContext();
  const { authState } = useOktaAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const [redirectToCustomerForm, setRedirectToCustomerForm] = useState(false);

  const [
    successModalContent,
    setSuccessModalContent,
    isSuccessModalShowing,
    showSuccessModal,
  ] = useSuccessModal();

  useEffect(() => {
    !authState?.isAuthenticated && openModal('/shopping-cart');
  }, [authState?.isAuthenticated]);

  useEffect(() => {
    document.title = 'Shopping Cart';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Halliburton shopping cart';

    document.head.appendChild(metaDescription);

    return () => {
      document.title = '';
      document.head.removeChild(metaDescription);
    };
  }, []);

  useEffect(() => {
    const trackViewChange = () => {
      appInsights.trackPageView({ name: location.pathname });
    };
    trackViewChange();
  }, [location.pathname]);

  const {
    mutate: mutateShoppingCartList,
    isLoading: isLoadingAddToShoppingCart,
  } = useMutation(
    (data) =>
      addItemToShoppingCart(
        data.productData,
        data.sap.toString(),
        data.quantity
      ),
    {
      onError: (error) => {
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] === '401') {
          openModal('/shopping-cart');
        } else {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#SC-2-${errorCode[1]}`,
          });
        }
      },
      onSuccess: (response) => {
        updateShoppingCartData(response);
        updateCartItemsNumber(response.length);
      },
    }
  );

  const {
    mutate: removeFromShoppingCartList,
    isLoading: isLoadingRemoveFromShoppingCart,
  } = useMutation(
    (data) => removeItemFromShoppingCart(data.sap.toString(), data.quantity),
    {
      onError: (error) => {
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] === '401') {
          openModal('/shopping-cart');
        } else {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#SC-3-${errorCode[1]}`,
          });
        }
      },
      onSuccess: (response) => {
        updateShoppingCartData(response);
        updateCartItemsNumber(response.length);
      },
    }
  );

  const {
    mutate: removeAllItemsFromShoppingCartList,
    isLoading: isLoadingRemoveAllItemsFromShoppingCart,
  } = useMutation((data) => removeAllItemsFromShoppingCart(data), {
    onError: (error) => {
      const errorCode = error.message.split('/');
      if (error.constructor.name === 'TypeError') {
        setError({
          title: 'Looks like something went wrong',
          message:
            'Network connection lost. Please check your network connection and try again.',
        });
      } else if (errorCode[1] === '401') {
        openModal('/shopping-cart');
      } else {
        setError({
          title: 'Looks like something went wrong',
          message:
            'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
          errorCode: `#SC-4-${errorCode[1]}`,
        });
      }
    },
    onSuccess: (response) => {
      updateShoppingCartData([]);
      updateCartItemsNumber(0);
    },
  });

  const { mutate: mutateRequestQuote, isLoading: isRequestingQuote } =
    useMutation((body) => requestQuote(body), {
      onError: (error) => {
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] === '401') {
          openModal('/shopping-cart');
        } else {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#SCPO-2-${errorCode[1]}`,
          });
        }
      },
      onSuccess: (response) => {
        updateShoppingCartData([]);
        updateCartItemsNumber(0);
        setSuccessModalContent({
          title: 'Request placed',
          message: 'Our team will be in touch shortly to discuss this further.',
        });
        showSuccessModal();
      },
    });

  const handleRedirectToCustomerForm = () => {
    setRedirectToCustomerForm(true);
  };

  const handleAddItemToShoppingCart = (productData, sap, quantity) => {
    mutateShoppingCartList({ productData, sap, quantity });
  };

  const handleRemoveItemFromCart = (sap, quantity) => {
    removeFromShoppingCartList({ sap, quantity });
  };

  const handleRemoveAllItemsFromCart = () => {
    removeAllItemsFromShoppingCartList();
  };

  const handleRequestQuote = (body) => {
    mutateRequestQuote(body);
  };
  return (
    <>
      <ErrorModal />
      <ShoppingCartViewContext.Provider
        value={{
          shoppingCartItemsData: shoppingCartItemsData,
          isDisabled: isLoadingAddToShoppingCart,
          itemBeingDeleted: isLoadingRemoveFromShoppingCart,
          isRequestingQuote: isRequestingQuote,
          isShoppingCartLoading: isShoppingCartLoading,
          isLoadingRemoveAllItemsFromShoppingCart: isLoadingRemoveAllItemsFromShoppingCart,
          addItemToShoppingCart: handleAddItemToShoppingCart,
          removeItemFromShoppingCart: handleRemoveItemFromCart,
          removeAllItemsFromCart: handleRemoveAllItemsFromCart,
          requestQuote: handleRequestQuote,
          redirectToCustomerForm: handleRedirectToCustomerForm,
        }}
      >
        <ShoppingCartViewContainer>
          {shoppingCartError ||
          (!isShoppingCartLoading && !Array.isArray(shoppingCartItemsData)) ? (
            <DataFetchErrorPlaceholderContainer>
              {authState?.isAuthenticated ? (
                <DataFetchErrorPlaceholder />
              ) : (
                <SignInMessage>
                  Please sign into your account to access this feature
                </SignInMessage>
              )}
            </DataFetchErrorPlaceholderContainer>
          ) : isShoppingCartLoading || !shoppingCartItemsData ? (
            <>
              <LoaderOuterContainer>
                <MyCartTitle>My cart</MyCartTitle>
                <SkeletonLoaderContainer>
                  <SkeletonRow height={50} width={'100%'} count={7} />
                </SkeletonLoaderContainer>
              </LoaderOuterContainer>
              <SuggestedItems />
            </>
          ) : !redirectToCustomerForm ? (
            <>
              <MyCart/>
              <SuggestedItems/>
            </>
          ) : (
            <ReturningUserContextProvider>
              <ClientFormPage />
            </ReturningUserContextProvider>
          )}
        </ShoppingCartViewContainer>
        <SuccessModal
          isOpen={isSuccessModalShowing}
          modalContent={successModalContent}
          onOkButtonClick={() => navigate('/')}
        />
      </ShoppingCartViewContext.Provider>
    </>
  );
};

export default ShoppingCart;
